import { useState, useEffect } from "react";

const Footer = () => {
    const [isSticky, setIsSticky] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setIsSticky(window.innerWidth > 768);
        };

        window.addEventListener("resize", handleResize);
        handleResize(); // Check on initial render

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div>
            <br />
            <br />
            <br />
            <div className="sidenav-overlay"></div>
            <div className="drag-target"></div>

            <footer className={`footer footer-light navbar-border navbar-shadow ${isSticky ? "fixed-bottom" : ""}`}>
                <p className="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2">
                    <div className="row">
                        <span className="col-md text-left d-none d-lg-block pt-1">
                            Copyright &copy; {new Date().getFullYear()}{" "}
                        </span>

                        <div className="col-md-8">
                            <div className="row justify-content-center">
                                <p className="col-md-3 text-left d-block d-md-inline-block pt-1">Jointly Developed by</p>
                                <div className="col-md-5">
                                    <table className="justify-content-left">
                                        <tr style={{ borderBottom: "thin solid", borderBottomColor: "grey" }}>
                                            <td style={{ whiteSpace: "nowrap" }}>
                                                <a className="text-bold-800 grey darken-2" href="http://www.bscl.gov.bd/" target="_blank" rel="noopener noreferrer">
                                                    Bangladesh Satellite Company Limited
                                                    <img style={{ height: "auto", width: "7%" }} className="brand-logo" alt="bscl logo" src="../../../app-assets/images/logo/bscl-logo.png" />
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ whiteSpace: "nowrap" }}>
                                                <a className="text-bold-800 grey darken-2" href="https://www.aiub.edu/" target="_blank" rel="noopener noreferrer">
                                                    American International University-Bangladesh
                                                    <img style={{ height: "auto", width: "7%" }} className="brand-logo" alt="aiub logo" src="../../../app-assets/images/logo/aiub-logo.png" />
                                                </a>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <span className="col-md text-right d-none d-lg-block pt-1" style={{ whiteSpace: "nowrap" }}>
                            TRP-Measurement-System <i className="ft-bar-chart-2 pink"></i>
                            <span id="scroll-top"></span>
                        </span>
                    </div>
                </p>
            </footer>
        </div>
    );
};

export default Footer;
