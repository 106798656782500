import { React, useState, useEffect, useCallback } from 'react';
import axiosConfig from '../axiosConfig';
import Header from '../Header/Header';
import MainMenu from '../MainMenu/MainMenu';
import Select from 'react-select';
import axios from 'axios';  // Import axios for cancel tokens





// Utility function to calculate the time ago
const timeAgo = (timestamp) => {
    const now = new Date();
    const past = new Date(timestamp);
    const seconds = Math.floor((now - past) / 1000);

    if (seconds < 0) return "Just now"; // Handle future timestamps

    if (seconds < 60) return `${seconds} seconds ago`;
    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) return `${interval} years ago`;
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) return `${interval} months ago`;
    interval = Math.floor(seconds / 86400);
    if (interval > 1) return `${interval} days ago`;
    interval = Math.floor(seconds / 3600);
    if (interval > 1) return `${interval} hours ago`;
    interval = Math.floor(seconds / 60);
    return `${interval} minutes ago`;
};






const DeviceDeployment = () => {
    const [queryRawData, setQueryRawData] = useState("");
    const [queryViewLogs, setQueryViewLogs] = useState("");
    const [queryNewDeviceRawData, setQueryNewDeviceRawData] = useState("");

    const [searchBy, setSearchBy] = useState("current_box_id");
    const [currentDeviceBoxID, setCurrentDeviceBoxID] = useState(null);
    const [householdName, setHouseholdName] = useState(null);
    const [householdID, setHouseholdID] = useState(null);
    const [newDeviceBoxID, setNewDeviceBoxID] = useState(null);


    const [allBoxIdsWithHH, setAllBoxIdsWithHH] = useState([]);
    const [loadingAvailableBoxIdsWithHH, setLoadingAvailableBoxIdsWithHH] = useState(false);


    const [allHHNamesWithNoDevice, setAllHHNamesWithNoDevice] = useState([]);
    const [loadingAvailableHHNamesWithNoDevice, setLoadingAvailableHHNamesWithNoDevice] = useState(false);


    const [allNewBoxes, setAllNewBoxes] = useState([]);
    const [loadingAvailableNewBoxes, setLoadingAvailableNewBoxes] = useState(false);


    const [householdInfo, setHouseholdInfo] = useState(null);
    const [loadingHouseholdInfo, setLoadingHouseholdInfo] = useState(false);
    const [errorMsgHouseholdInfo, setErrorMsgHouseholdInfo] = useState("Please select a valid ID");


    const [deviceRawData, setDeviceRawData] = useState(null);
    const [loadingDeviceRawData, setLoadingDeviceRawData] = useState(false);
    const [errorMsgDeviceRawData, setErrorMsgDeviceRawData] = useState("Please select a valid ID");

    const [newDeviceRawData, setNewDeviceRawData] = useState(null);
    const [loadingNewDeviceRawData, setLoadingNewDeviceRawData] = useState(false);
    const [errorMsgNewDeviceRawData, setErrorMsgNewDeviceRawData] = useState("Please select a valid ID");

    const [householdViewLogs, setHouseholdViewLogs] = useState(null);
    const [loadingHouseholdViewLogs, setLoadingHouseholdViewLogs] = useState(false);
    const [errorMsgHouseholdViewLogs, setErrorMsgHouseholdViewLogs] = useState("Please select a valid ID");

    const [msgDeviceConnect, setMsgDeviceConnect] = useState(null);
    const [errorMsgDeviceConnect, setErrorMsgDeviceConnect] = useState(null);

    const [loadingConnectHouseholdButton, setLoadingConnectHouseholdButton] = useState(false);

    let refreshToggle = false;



    // ✅ Function to Fetch All API Data
    const fetchInitialData = async () => {
        // Fetch available box ids which have HH
        setLoadingAvailableBoxIdsWithHH(false);
        await axiosConfig.get("/device-check/availableDeviceBoxList-HasHousehold").then((rsp) => {
            setAllBoxIdsWithHH(rsp.data);
            setLoadingAvailableBoxIdsWithHH(true);
        }).catch((err) => {
            if (err.response.status === 422) {
                // handle error
            }
        });

        // Fetch available HH which dont have device connected
        setLoadingAvailableHHNamesWithNoDevice(false);
        await axiosConfig.get("/device-check/availableAllValidHouseholdNameList").then((rsp) => {
            setAllHHNamesWithNoDevice(rsp.data);
            setLoadingAvailableHHNamesWithNoDevice(true);
        }).catch((err) => {
            if (err.response.status === 422) {
                // handle error
            }
        });


        // Fetch available box ids which have deselected HH and not connected to any household
        setLoadingAvailableNewBoxes(false);
        await axiosConfig.get("device-check/available-freeBoxes-or-deselected-householdBoxes").then((rsp) => {
            setAllNewBoxes(rsp.data);
            setLoadingAvailableNewBoxes(true);
        }).catch((err) => {
            if (err.response.status === 422) {
                // handle error
            }
        });

    }


    useEffect(() => {

        fetchInitialData();

    }, []);



    // useCallback for fetchHouseholdInfo to ensure it's stable and cancel old requests
    const fetchHouseholdInfo = useCallback(() => {
        if (householdID) {
            const source = axios.CancelToken.source();  // Create cancel token
            axiosConfig.get("/household-info/" + householdID, { cancelToken: source.token }).then((rsp) => {
                setHouseholdInfo(rsp.data.household_info);
                setLoadingHouseholdInfo(true);
                setErrorMsgHouseholdInfo(null);
            }).catch((err) => {
                if (axios.isCancel(err)) {
                    console.log("Request canceled for Box Info:", householdID);
                } else {
                    if (err.response.status === 414) {
                        setErrorMsgHouseholdInfo(err.response.data.error.device_box_id);
                    } else if (err.response.status === 415) {
                        setErrorMsgHouseholdInfo(err.response.data.error);
                    } else {
                        setErrorMsgHouseholdInfo("Invalid operation, Please try again");
                    }
                }
                setLoadingHouseholdInfo(true);


            });

        }
    }, [householdID]);

    // useCallback for fetchDeviceRawData with request cancellation
    const fetchDeviceRawData = useCallback(() => {
        if (householdID) {
            const source = axios.CancelToken.source();  // Create cancel token
            axiosConfig.get("/devicerawdata-household/" + householdID, { cancelToken: source.token }).then((rsp) => {
                setDeviceRawData(rsp.data.device_raw_data);
                setLoadingDeviceRawData(true);
                setErrorMsgDeviceRawData(null);
            }).catch((err) => {
                if (axios.isCancel(err)) {
                    console.log("Request canceled for Box Info:", householdID);
                } else {
                    if (err.response.status === 414) {
                        setErrorMsgDeviceRawData(err.response.data.error.household_id);
                    } else if (err.response.status === 415) {
                        setErrorMsgDeviceRawData(err.response.data.error);
                    } else {
                        setErrorMsgDeviceRawData("Invalid operation, Please try again");
                    }
                }
                setLoadingDeviceRawData(true);

            });
        }
    }, [householdID]);



    // useCallback for fetchNewDeviceRawData with request cancellation
    const fetchNewDeviceRawData = useCallback(() => {
        if (newDeviceBoxID) {
            const source = axios.CancelToken.source();  // Create cancel token
            axiosConfig.get("/newdevicerawdata-byDeviceID/" + newDeviceBoxID, { cancelToken: source.token }).then((rsp) => {
                setNewDeviceRawData(rsp.data.device_raw_data);
                setLoadingNewDeviceRawData(true);
                setErrorMsgNewDeviceRawData(null);
            }).catch((err) => {
                if (axios.isCancel(err)) {
                    console.log("Request canceled for Box Info:", newDeviceBoxID);
                } else {
                    if (err.response.status === 414) {
                        setErrorMsgNewDeviceRawData(err.response.data.error.device_id);
                    } else if (err.response.status === 415) {
                        setErrorMsgNewDeviceRawData(err.response.data.error);
                    } else {
                        setErrorMsgNewDeviceRawData("Invalid operation, Please try again");
                    }
                }
                setLoadingNewDeviceRawData(true);

            });
        }
    }, [newDeviceBoxID]);

    // useCallback for fetchDeviceViewLogs with request cancellation
    const fetchHouseholdViewLogs = useCallback(() => {
        if (householdID) {
            const source = axios.CancelToken.source();  // Create cancel token
            axiosConfig.get("/householdviewlogs/" + householdID, { cancelToken: source.token }).then((rsp) => {
                setHouseholdViewLogs(rsp.data.household_view_logs);
                setLoadingHouseholdViewLogs(true);
                setErrorMsgHouseholdViewLogs(null);
            }).catch((err) => {
                if (axios.isCancel(err)) {
                    console.log("Request canceled for Box Info:", householdID);
                } else {
                    if (err.response.status === 414) {
                        setErrorMsgHouseholdViewLogs(err.response.data.error.household_id);
                    } else if (err.response.status === 415) {
                        setErrorMsgHouseholdViewLogs(err.response.data.error);
                    } else {
                        setErrorMsgHouseholdViewLogs("Invalid operation, Please try again");
                    }
                }
                setLoadingHouseholdViewLogs(true);

            });
        }
    }, [householdID]);




    // useEffect to handle Household ID change (clears previous data immediately)
    useEffect(() => {
        //setMsgDeviceConnect(null);
        //setErrorMsgDeviceConnect(null);
        if (householdID) {
            // Clear all previous data when a new deviceBoxID is selected
            setHouseholdInfo(null);
            setDeviceRawData(null);
            setHouseholdViewLogs(null);

            setLoadingHouseholdInfo(false);
            setErrorMsgHouseholdInfo(null);
            fetchHouseholdInfo(); // Fetch immediately

            setLoadingDeviceRawData(false);
            setErrorMsgDeviceRawData(null);
            fetchDeviceRawData(); // Fetch immediately

            setLoadingHouseholdViewLogs(false);
            setErrorMsgHouseholdViewLogs(null);
            fetchHouseholdViewLogs(); // Fetch immediately
        } else {
            // Clear all previous data when a new deviceBoxID is selected
            setHouseholdInfo(null);
            setDeviceRawData(null);
            setHouseholdViewLogs(null);

            setLoadingHouseholdInfo(false);
            setErrorMsgHouseholdInfo("Please select a valid ID");
            //fetchHouseholdInfo(); // Fetch immediately

            setLoadingDeviceRawData(false);
            setErrorMsgDeviceRawData("Please select a valid ID");
            //fetchDeviceRawData(); // Fetch immediately

            setLoadingHouseholdViewLogs(false);
            setErrorMsgHouseholdViewLogs("Please select a valid ID");
            //fetchHouseholdViewLogs(); // Fetch immediately
        }
    }, [householdID]);


    // useEffect to handle New Device Box ID change (clears previous data immediately)
    useEffect(() => {
        if (newDeviceBoxID) {
            //Clear previous data
            setNewDeviceRawData(null);

            setLoadingNewDeviceRawData(false);
            setErrorMsgNewDeviceRawData(null);
            fetchNewDeviceRawData(); // Fetch immediately
        } else {
            //Clear previous data
            setNewDeviceRawData(null);

            setLoadingNewDeviceRawData(false);
            setErrorMsgNewDeviceRawData("Please select a valid ID");
            //fetchNewDeviceRawData(); // Fetch immediately
        }
    }, [newDeviceBoxID]);


    // Handle Global Refresh (refresh all data)
    const handleGlobalRefresh = () => {
        // Fetch Initial Data again for sync (Current Box ID, Household ID, New Box ID)
        fetchInitialData();

        if (householdID) {
            // Clear all previous data when a new deviceBoxID is selected
            setHouseholdInfo(null);
            setDeviceRawData(null);
            setHouseholdViewLogs(null);

            setLoadingHouseholdInfo(false);
            setErrorMsgHouseholdInfo(null);
            fetchHouseholdInfo(); // Fetch immediately

            setLoadingDeviceRawData(false);
            setErrorMsgDeviceRawData(null);
            fetchDeviceRawData(); // Fetch immediately

            setLoadingHouseholdViewLogs(false);
            setErrorMsgHouseholdViewLogs(null);
            fetchHouseholdViewLogs(); // Fetch immediately
        } else {
            // Clear all previous data when a new deviceBoxID is selected
            setHouseholdInfo(null);
            setDeviceRawData(null);
            setHouseholdViewLogs(null);

            setLoadingHouseholdInfo(false);
            setErrorMsgHouseholdInfo("Please select a valid ID");
            //fetchHouseholdInfo(); // Fetch immediately

            setLoadingDeviceRawData(false);
            setErrorMsgDeviceRawData("Please select a valid ID");
            //fetchDeviceRawData(); // Fetch immediately

            setLoadingHouseholdViewLogs(false);
            setErrorMsgHouseholdViewLogs("Please select a valid ID");
            //fetchHouseholdViewLogs(); // Fetch immediately
        }

        if (newDeviceBoxID) {
            //Clear previous data
            setNewDeviceRawData(null);

            setLoadingNewDeviceRawData(false);
            setErrorMsgNewDeviceRawData(null);
            fetchNewDeviceRawData(); // Fetch immediately
        } else {
            //Clear previous data
            setNewDeviceRawData(null);

            setLoadingNewDeviceRawData(false);
            setErrorMsgNewDeviceRawData("Please select a valid ID");
            //fetchNewDeviceRawData(); // Fetch immediately
        }

    };


    // Handle Individual Refresh for Device Box Info
    const handleRefreshHouseholdInfo = () => {
        if (householdID) {
            //Clear previous data
            setHouseholdInfo(null);

            setLoadingHouseholdInfo(false);
            setErrorMsgHouseholdInfo(null);
            fetchHouseholdInfo(); // Fetch immediately
        } else {
            //Clear previous data
            setHouseholdInfo(null);

            setLoadingHouseholdInfo(false);
            setErrorMsgHouseholdInfo("Please select a valid ID");
            //fetchHouseholdInfo(); // Fetch immediately
        }

    };


    // Handle Individual Refresh for Device Raw Data
    const handleRefreshDeviceRawData = () => {
        if (householdID) {
            //Clear previous data
            setDeviceRawData(null);

            setLoadingDeviceRawData(false);
            setErrorMsgDeviceRawData(null);
            fetchDeviceRawData(); // Fetch immediately
        } else {
            //Clear previous data
            setDeviceRawData(null);

            setLoadingDeviceRawData(false);
            setErrorMsgDeviceRawData("Please select a valid ID");
            //fetchDeviceRawData(); // Fetch immediately
        }

    };


    // Handle Individual Refresh for Device View Logs
    const handleRefreshHouseholdViewLogs = () => {
        if (householdID) {
            //Clear previous data
            setHouseholdViewLogs(null);

            setLoadingHouseholdViewLogs(false);
            setErrorMsgHouseholdViewLogs(null);
            fetchHouseholdViewLogs(); // Fetch immediately();
        } else {
            //Clear previous data
            setHouseholdViewLogs(null);

            setLoadingHouseholdViewLogs(false);
            setErrorMsgHouseholdViewLogs("Please select a valid ID");
            //fetchHouseholdViewLogs(); // Fetch immediately
        }
    };


    // Handle Individual Refresh for Device Raw Data
    const handleRefreshNewDeviceRawData = () => {
        if (newDeviceBoxID) {
            //Clear previous data
            setNewDeviceRawData(null);

            setLoadingNewDeviceRawData(false);
            setErrorMsgNewDeviceRawData(null);
            fetchNewDeviceRawData(); // Fetch immediately
        } else {
            //Clear previous data
            setNewDeviceRawData(null);

            setLoadingNewDeviceRawData(false);
            setErrorMsgNewDeviceRawData("Please select a valid ID");
            //fetchNewDeviceRawData(); // Fetch immediately
        }
    };


    // Connect Device Box with testing household
    const householdConnect = () => {
        setLoadingConnectHouseholdButton(true);
        setMsgDeviceConnect(null);
        setErrorMsgDeviceConnect(null);
        axiosConfig.post("/deviceboxconnect", {
            household_id: householdID,
            device_box_id: newDeviceBoxID
        }).then((rsp) => {
            console.log(rsp.data);

            //Fetch Initial Data again for sync (Current Box ID, Household ID, New Box ID)
            fetchInitialData();

            alert(rsp.data.message);
            setMsgDeviceConnect(rsp.data.message);
            setErrorMsgDeviceConnect(null);
            //Update all the value of this page
            if (searchBy === "current_box_id"){
                setCurrentDeviceBoxID(rsp.data.device_box_id);
            }
            setHouseholdID(rsp.data.household_id);
            setNewDeviceBoxID(null);

            // Clear all previous data when a new deviceBoxID is Connected/selected
            setHouseholdInfo(null);
            setDeviceRawData(null);
            setHouseholdViewLogs(null);
            setNewDeviceRawData(null);

            setLoadingHouseholdInfo(false);
            setErrorMsgHouseholdInfo(null);
            fetchHouseholdInfo(); // Fetch immediately

            setLoadingDeviceRawData(false);
            setErrorMsgDeviceRawData(null);
            fetchDeviceRawData(); // Fetch immediately

            setLoadingHouseholdViewLogs(false);
            setErrorMsgHouseholdViewLogs(null);
            fetchHouseholdViewLogs(); // Fetch immediately

            setLoadingNewDeviceRawData(false);
            setErrorMsgNewDeviceRawData("Please select a valid ID");
            //fetchDeviceRawData(); // Fetch immediately

            setLoadingConnectHouseholdButton(false);
            
        }, (err) => {
            setMsgDeviceConnect(null);
            if (err.response.status === 414) {
                setErrorMsgDeviceConnect("The selected options are not correct");
                alert("The selected options are not correct");
            } else if (err.response.status === 415) {
                setErrorMsgDeviceConnect(err.response.data.error);
                alert(err.response.data.error);
            } else {
                setErrorMsgDeviceConnect("Invalid operation, Please try again");
                alert("Invalid operation, Please try again");
            }
            console.log(err.response);

            setLoadingConnectHouseholdButton(false);

        });

    };


    // console.log(currentDeviceBoxID)
    // console.log(householdName)
    // console.log(householdID)

    const SearchRawData = (data) => {
        return data.filter(
            (item) =>
                item?.channel_name?.toLowerCase().includes(queryRawData.toLowerCase())
        );
    };

    const SearchViewLogs = (data) => {
        return data.filter(
            (item) =>
                item?.channel_name?.toLowerCase().includes(queryViewLogs.toLowerCase())
        );
    };

    const SearchNewDeviceRawData = (data) => {
        return data.filter(
            (item) =>
                item?.channel_name?.toLowerCase().includes(queryNewDeviceRawData.toLowerCase())
        );
    };


    // Update Device Box info last request time real time count
    const [lastRequest, setLastRequest] = useState(householdInfo ? householdInfo.last_request : null);
    const [lastRequestTimeAgo, setLastRequestTimeAgo] = useState('');

    useEffect(() => {
        const updateLastRequest = () => {
            if (lastRequest) {
                setLastRequestTimeAgo(timeAgo(lastRequest));
            }
        };

        // Update time ago immediately on mount
        updateLastRequest();

        const interval = setInterval(() => {
            updateLastRequest(); // Update every minute
        }, 1000); // 60 seconds

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, [lastRequest]);

    // Update lastRequest when householdInfo changes
    useEffect(() => {
        if (householdInfo) {
            setLastRequest(householdInfo.last_request);
        }
    }, [householdInfo]);
    //End of Update Device Box info last request time real time count




    return (
        <div><Header title="Device Deployment" />
            <MainMenu menu="DeviceDeployment" />
            <div class="app-content content" style={{ backgroundColor: "azure", minHeight: "36em" }} >
                <div class="content-overlay"></div>
                <div class="content-wrapper">
                    <div class="content-header row">
                    </div>
                    <div class="content-body">




                        {/* Loading state */}
                        {(loadingAvailableBoxIdsWithHH && loadingAvailableHHNamesWithNoDevice && loadingAvailableNewBoxes) ? (


                            <><div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-content collapse show">
                                            <div className="card-body card-dashboard">


                                                <div className="row " >
                                                    {/* Device Box ID Section */}


                                                    <div className='col-md-7'>
                                                        <label style={{ color: "blue", fontWeight: "bold" }}>Household Search by</label>
                                                        <div class="row text-nowrap">
                                                            <div className='col-md-6'>
                                                                <select class="custom-select " value={searchBy} onChange={(e) => { setSearchBy(e.target.value); setCurrentDeviceBoxID(null); setHouseholdName(null); setHouseholdID(null); }}>
                                                                    <option value="current_box_id">Current BoxID</option>
                                                                    <option value="household_id">Household ID</option>


                                                                </select>
                                                                <b className='h2'>  :</b>
                                                            </div>


                                                            <div className='col-md-4'>
                                                                {(searchBy == "current_box_id") ? (
                                                                    <Select
                                                                        placeholder="Select BoxID"
                                                                        options={allBoxIdsWithHH.map(box => ({ label: box.id, value: box.id, device_id: box.device_id }))}
                                                                        onChange={opt => { setCurrentDeviceBoxID(opt.value); setHouseholdID(opt.device_id); }}
                                                                        value={allBoxIdsWithHH.find(box => box.id === currentDeviceBoxID) ? { label: currentDeviceBoxID, value: currentDeviceBoxID } : null} // Update value handling
                                                                        menuPortalTarget={document.body} // Render the menu in a portal
                                                                        styles={{
                                                                            control: (provided) => ({
                                                                                ...provided,
                                                                                zIndex: 2, // Ensure the Select component has a higher z-index
                                                                            }),
                                                                            menu: (provided) => ({
                                                                                ...provided,
                                                                                zIndex: 2, // Ensure the menu has a higher z-index
                                                                            }),
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <Select
                                                                        placeholder="Select Household-ID"
                                                                        options={allHHNamesWithNoDevice.map(hh => ({ label: hh.device_name, value: hh.device_name, device_id: hh.device_id }))}
                                                                        onChange={opt => { setHouseholdName(opt.value); setHouseholdID(opt.device_id); }}
                                                                        value={allHHNamesWithNoDevice.find(hh => hh.device_name === householdName) ? { label: householdName, value: householdName } : null} // Update value handling
                                                                        menuPortalTarget={document.body} // Render the menu in a portal
                                                                        styles={{
                                                                            control: (provided) => ({
                                                                                ...provided,
                                                                                zIndex: 2, // Ensure the Select component has a higher z-index
                                                                            }),
                                                                            menu: (provided) => ({
                                                                                ...provided,
                                                                                zIndex: 2, // Ensure the menu has a higher z-index
                                                                            }),
                                                                        }}
                                                                    />
                                                                )}


                                                            </div>
                                                            <div
                                                                className="col-md-2 d-flex justify-content-md-center justify-content-start align-items-center text-center"
                                                            >
                                                                <div
                                                                    style={{
                                                                        borderLeft: "2px dotted gray",
                                                                        height: "30px", // Explicitly set height for visibility
                                                                    }}
                                                                ></div>
                                                            </div>


                                                        </div>
                                                    </div>


                                                    {/* Vertical dotted line */}






                                                    <div className='col-md-3'>
                                                        <label style={{ color: "orange", fontWeight: "bold" }}>New BoxID</label>
                                                        <Select
                                                            placeholder="Select Box ID"
                                                            options={allNewBoxes.map(box => ({ label: box.id, value: box.id }))}
                                                            onChange={opt => setNewDeviceBoxID(opt.value)}
                                                            value={allNewBoxes.find(box => box.id === newDeviceBoxID) ? { label: newDeviceBoxID, value: newDeviceBoxID } : null} // Update value handling
                                                            menuPortalTarget={document.body} // Render the menu in a portal
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    zIndex: 2, // Ensure the Select component has a higher z-index
                                                                }),
                                                                menu: (provided) => ({
                                                                    ...provided,
                                                                    zIndex: 2, // Ensure the menu has a higher z-index
                                                                }),
                                                            }}
                                                        />
                                                    </div>


                                                    <div className="col-md-2 d-flex flex-column justify-content-end text-md-end">
                                                        <button
                                                            className="btn btn-primary"
                                                            disabled={((!newDeviceBoxID) || (!currentDeviceBoxID && !householdID)|| loadingConnectHouseholdButton || (!householdInfo?.deselected_status) || (householdInfo?.deselected_status === 'Selected'))}
                                                            onClick={householdConnect}
                                                        >
                                                            Connect to Household
                                                        </button>
                                                        <span className="text-success">{ }</span>
                                                        <span className="text-danger">{ }</span>
                                                    </div>




                                                </div>




                                                {/* Refresh Button Section */}
                                                <div className="row mt-3">
                                                    {/* "Refresh" button, centered on small screens */}
                                                    <div className="col-12 col-md-6 d-flex justify-content-start order-2 order-md-1 mt-2 mt-md-0">
                                                        <button
                                                            className="btn-sm btn-success d-flex align-items-center justify-content-center"
                                                            onClick={handleGlobalRefresh}
                                                            style={{ width: "200px" }}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="24"
                                                                height="24"
                                                                fill="currentColor"
                                                                className="bi bi-arrow-clockwise"
                                                                viewBox="0 0 16 16"
                                                                style={{ marginRight: "10px", fontWeight: "bold" }}
                                                            >
                                                                <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                                            </svg>
                                                            Refresh
                                                        </button>
                                                    </div>


                                                    {/* "Send for Approval" button, right-aligned on large screens */}
                                                    <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end order-1 order-md-2">
                                                        {/* <button
                                                            className="btn btn-warning d-flex align-items-center justify-content-center"
                                                            style={{ width: "150px" }}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="24"
                                                                height="24"
                                                                fill="currentColor"
                                                                className="bi bi-arrow-clockwise"
                                                                viewBox="0 0 16 16"
                                                                style={{ marginRight: "10px", fontWeight: "bold" }}
                                                            >
                                                                <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                                            </svg>
                                                            Sent for Approval
                                                        </button> */}
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                                {/*Loading & Conditionally render based on errorMsgBoxInfo */}
                                {errorMsgHouseholdInfo === null ? (
                                    loadingHouseholdInfo ? (

                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card">

                                                    <div class="card-content collapse show">
                                                        <div class="card-body card-dashboard">

                                                            <div class="row">
                                                                <div className="col-md-7 d-flex align-items-center text-nowrap">
                                                                    {/* Device Box Info Title */}
                                                                    <h1 className="card-title mx-2" style={{ color: "blue", fontWeight: "bold" }}>Household Info</h1>

                                                                    {/* Refresh Button */}
                                                                    <button
                                                                        className="btn btn-primary btn-sm d-flex align-items-center mb-2"
                                                                        onClick={handleRefreshHouseholdInfo}
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="18"
                                                                            height="18"
                                                                            fill="currentColor"
                                                                            className="bi bi-arrow-clockwise"
                                                                            viewBox="0 0 16 16"
                                                                        >
                                                                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                                <div class="col-md-5"></div>

                                                            </div>

                                                            <div class="" >
                                                                <table class="table nowrap table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className='nowrap' style={{ borderRight: "none" }}>Household ID: </th>
                                                                            <th className='' style={{ borderLeft: "none" }}><span className='text-primary'>{householdInfo.device_name}</span> ({householdInfo.deselected_status == 'Deselected' ? <span className='text-success'>{householdInfo.deselected_status}</span> : <span className='text-danger'>{householdInfo.deselected_status}</span> })</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className='text-info' style={{ borderRight: "none" }}>Last Active: </td>
                                                                            <td style={{ borderLeft: "none" }}>
                                                                                {householdInfo.last_request ? (
                                                                                    `${lastRequestTimeAgo} (${householdInfo.last_request})`                   //lastRequestTimeAgo + " ( " + deviceBoxInfo.active_duration + " ) "
                                                                                ) : (
                                                                                    <span className="text-danger">হাউজ-হোল্ডে কখনো ডাটা আসেনি</span>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-info' style={{ borderRight: "none" }}>Connected Device: </td>
                                                                            <td style={{ borderLeft: "none" }}>
                                                                                {householdInfo.connected_box_id ? (
                                                                                    `${householdInfo.connected_box_id} (Connected at: ${householdInfo.box_connected_at})`
                                                                                ) : (
                                                                                    <span className="text-danger">ডিভাইস সংযুক্ত নয়</span>
                                                                                )}
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td style={{ borderRight: "none" }}>Contact Person: </td>
                                                                            <td style={{ borderLeft: "none" }}>{householdInfo.contact_person}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ borderRight: "none" }}>Contact Number : </td>
                                                                            <td style={{ borderLeft: "none" }}>{householdInfo.contact_number}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ borderRight: "none" }}>SEC : </td>
                                                                            <td style={{ borderLeft: "none" }}>{householdInfo.economic_status}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ borderRight: "none" }}>Address : </td>
                                                                            <td style={{ borderLeft: "none" }}>{householdInfo.address}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ borderRight: "none" }}>Region : </td>
                                                                            <td style={{ borderLeft: "none" }}>{householdInfo.district}</td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                            </div>





                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                    ) : (
                                        <div className="card">
                                            <div className="card-header">
                                                <h1 class="card-title" style={{ color: "blue", fontWeight: "bold" }}>Household Info</h1>
                                                <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif?20170503175831" className="rounded mx-auto d-block" alt="Loading" />
                                            </div>
                                        </div>

                                    )

                                ) : (
                                    <div class="card">
                                        <div class="card-header">
                                            <div className="col-md-7 d-flex align-items-center text-nowrap">
                                                {/* Device Box Info Title */}
                                                <h1 className="card-title mx-2" style={{ color: "blue", fontWeight: "bold" }}>Household Info</h1>

                                                {/* Refresh Button */}
                                                <button
                                                    className="btn btn-primary btn-sm d-flex align-items-center mb-2"
                                                    onClick={handleRefreshHouseholdInfo}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="18"
                                                        height="18"
                                                        fill="currentColor"
                                                        className="bi bi-arrow-clockwise"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                                    </svg>
                                                </button>
                                            </div>
                                            <h4><span class="danger">{errorMsgHouseholdInfo}</span></h4>
                                        </div>
                                    </div>
                                )}


                                {errorMsgDeviceRawData === null ? (
                                    loadingDeviceRawData ? (

                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card">

                                                    <div class="card-content collapse show">
                                                        <div class="card-body card-dashboard">

                                                            <div class="row">
                                                                <div className="col-md-7 d-flex align-items-center text-nowrap">
                                                                    {/* Device Box Info Title */}
                                                                    <h1 className="card-title mx-2" style={{ color: "blue", fontWeight: "bold" }}>Household Connected Device Raw Data</h1>

                                                                    {/* Refresh Button */}
                                                                    <button
                                                                        className="btn btn-primary btn-sm d-flex align-items-center mb-2"
                                                                        onClick={handleRefreshDeviceRawData}
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="18"
                                                                            height="18"
                                                                            fill="currentColor"
                                                                            className="bi bi-arrow-clockwise"
                                                                            viewBox="0 0 16 16"
                                                                        >
                                                                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                                <div class="col-md-5"><input type="text" class="search form-control round border-primary mb-1" placeholder="Search With Channel" onChange={e => setQueryRawData(e.target.value)} />
                                                                </div>

                                                            </div>

                                                            <div className="table-responsive" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                                <table className="table display nowrap table-striped table-bordered">
                                                                    <thead className="thead-dark" style={{ position: 'sticky', top: 0, backgroundColor: '#343a40', zIndex: 1020 }}>
                                                                        <tr>
                                                                            <th>Channel</th>
                                                                            <th>End Time</th>
                                                                            <th>Device ID</th>
                                                                            <th>Server Receive Time</th>
                                                                            <th>Start Time</th>
                                                                            <th>People</th>
                                                                            <th>Data Sequence</th>
                                                                            <th>Track ID</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {SearchRawData(deviceRawData).map((rawData) => (
                                                                            <tr key={rawData.id}>
                                                                                <td>
                                                                                    {rawData.channel_id === '999' ? (
                                                                                        <div>No Content</div>
                                                                                    ) : (
                                                                                        <div style={{ whiteSpace: 'nowrap' }}>
                                                                                            <img
                                                                                                className="img-fluid"
                                                                                                alt=""
                                                                                                style={{ maxWidth: '3rem' }}
                                                                                                src={`../../channels/logos/${rawData.logo}`}
                                                                                            />
                                                                                            {rawData.channel_name}
                                                                                        </div>
                                                                                    )}
                                                                                </td>
                                                                                <td>{rawData.finish}</td>
                                                                                <td>{rawData.device_id}</td>
                                                                                <td>{rawData.server_time}</td>
                                                                                <td>{rawData.start}</td>
                                                                                <td>{rawData.people}</td>
                                                                                <td>{rawData.offline}</td>
                                                                                <td>{rawData.id}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>



                                    ) : (
                                        <div className="card">
                                            <div className="card-header">
                                                <h1 class="card-title" style={{ color: "blue", fontWeight: "bold" }}>Household Connected Device Raw Data</h1>
                                                <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif?20170503175831" className="rounded mx-auto d-block" alt="Loading" />
                                            </div>
                                        </div>

                                    )

                                ) : (
                                    <div class="card">
                                        <div class="card-header">
                                            <div className="col-md-7 d-flex align-items-center text-nowrap">
                                                {/* Device Box Info Title */}
                                                <h1 className="card-title mx-2" style={{ color: "blue", fontWeight: "bold" }}>Household Connected Device Raw Data</h1>

                                                {/* Refresh Button */}
                                                <button
                                                    className="btn btn-primary btn-sm d-flex align-items-center mb-2"
                                                    onClick={handleRefreshDeviceRawData}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="18"
                                                        height="18"
                                                        fill="currentColor"
                                                        className="bi bi-arrow-clockwise"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                                    </svg>
                                                </button>
                                            </div>
                                            <h4><span class="danger">{errorMsgDeviceRawData}</span></h4>
                                        </div>
                                    </div>
                                )}


                                {errorMsgHouseholdViewLogs === null ? (
                                    loadingHouseholdViewLogs ? (

                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card">

                                                    <div class="card-content collapse show">
                                                        <div class="card-body card-dashboard">

                                                            <div class="row">
                                                                <div className="col-md-7 d-flex align-items-center text-nowrap">
                                                                    {/* Household Box Info Title */}
                                                                    <h1 className="card-title mx-2" style={{ color: "blue", fontWeight: "bold" }}>Household View Logs</h1>

                                                                    {/* Refresh Button */}
                                                                    <button
                                                                        className="btn btn-primary btn-sm d-flex align-items-center mb-2"
                                                                        onClick={handleRefreshHouseholdViewLogs}
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="18"
                                                                            height="18"
                                                                            fill="currentColor"
                                                                            className="bi bi-arrow-clockwise"
                                                                            viewBox="0 0 16 16"
                                                                        >
                                                                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                                <div class="col-md-5"><input type="text" class="search form-control round border-primary mb-1" placeholder="Search With Channel" onChange={e => setQueryViewLogs(e.target.value)} />
                                                                </div>

                                                            </div>

                                                            <div className="table-responsive" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                                <table className="table display nowrap table-striped table-bordered">
                                                                    <thead className="thead-dark" style={{ position: 'sticky', top: 0, backgroundColor: '#343a40', zIndex: 1020 }}>
                                                                        <tr>
                                                                            <th>Channel</th>
                                                                            <th>Last Watching Time</th>
                                                                            <th>Duration</th>
                                                                            <th>User (Button)</th>
                                                                            {/* <th>Device Box ID</th> */}
                                                                            <th>Household</th>
                                                                            <th>Track ID</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {SearchViewLogs(householdViewLogs).map((viewLogs) => (
                                                                            <tr key={viewLogs.id}>
                                                                                <td>
                                                                                    <div style={{ whiteSpace: 'nowrap' }}>
                                                                                        <img className="img-fluid" alt="" style={{ maxWidth: "3rem" }} src={`../../channels/logos/${viewLogs.logo}`} />
                                                                                        {viewLogs.channel_name}
                                                                                    </div>
                                                                                </td>
                                                                                <td>{viewLogs.finished_watching_at}</td>
                                                                                <td>{viewLogs.duration}</td>
                                                                                <td>{viewLogs.user_index + 1}</td>
                                                                                {/* <td>{viewLogs.box_id}</td> */}
                                                                                <td>{viewLogs.household_name}</td>
                                                                                <td>{viewLogs.id}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>



                                    ) : (
                                        <div className="card">
                                            <div className="card-header">
                                                <h1 class="card-title" style={{ color: "blue", fontWeight: "bold" }}>Household View Logs</h1>
                                                <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif?20170503175831" className="rounded mx-auto d-block" alt="Loading" />
                                            </div>
                                        </div>

                                    )

                                ) : (
                                    <div class="card">
                                        <div class="card-header">
                                            <div className="col-md-7 d-flex align-items-center text-nowrap">
                                                {/* Household Box Info Title */}
                                                <h1 className="card-title mx-2" style={{ color: "blue", fontWeight: "bold" }}>Household View Logs</h1>

                                                {/* Refresh Button */}
                                                <button
                                                    className="btn btn-primary btn-sm d-flex align-items-center mb-2"
                                                    onClick={handleRefreshHouseholdViewLogs}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="18"
                                                        height="18"
                                                        fill="currentColor"
                                                        className="bi bi-arrow-clockwise"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                                    </svg>
                                                </button>
                                            </div>
                                            <h4><span class="danger">{errorMsgHouseholdViewLogs}</span></h4>
                                        </div>
                                    </div>
                                )}



                                {errorMsgNewDeviceRawData === null ? (
                                    loadingNewDeviceRawData ? (

                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card">

                                                    <div class="card-content collapse show">
                                                        <div class="card-body card-dashboard">

                                                            <div class="row">
                                                                <div className="col-md-7 d-flex align-items-center text-nowrap">
                                                                    {/* Device Box Info Title */}
                                                                    <h1 className="card-title mx-2" style={{ color: "orange", fontWeight: "bold" }}>New Device Raw Data</h1>

                                                                    {/* Refresh Button */}
                                                                    <button
                                                                        className="btn btn-warning btn-sm d-flex align-items-center mb-2"
                                                                        onClick={handleRefreshNewDeviceRawData}
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="18"
                                                                            height="18"
                                                                            fill="currentColor"
                                                                            className="bi bi-arrow-clockwise"
                                                                            viewBox="0 0 16 16"
                                                                        >
                                                                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                                <div class="col-md-5"><input type="text" class="search form-control round border-primary mb-1" placeholder="Search With Channel" onChange={e => setQueryNewDeviceRawData(e.target.value)} />
                                                                </div>

                                                            </div>

                                                            <div className="table-responsive" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                                <table className="table display nowrap table-striped table-bordered">
                                                                    <thead className="thead-dark" style={{ position: 'sticky', top: 0, backgroundColor: '#343a40', zIndex: 1020 }}>
                                                                        <tr>
                                                                            <th>Channel</th>
                                                                            <th>End Time</th>
                                                                            <th>Device ID</th>
                                                                            <th>Server Receive Time</th>
                                                                            <th>Start Time</th>
                                                                            <th>People</th>
                                                                            <th>Data Sequence</th>
                                                                            <th>Track ID</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {SearchNewDeviceRawData(newDeviceRawData).map((rawData) => (
                                                                            <tr key={rawData.id}>
                                                                                <td>
                                                                                    {rawData.channel_id === '999' ? (
                                                                                        <div>No Content</div>
                                                                                    ) : (
                                                                                        <div style={{ whiteSpace: 'nowrap' }}>
                                                                                            <img
                                                                                                className="img-fluid"
                                                                                                alt=""
                                                                                                style={{ maxWidth: '3rem' }}
                                                                                                src={`../../channels/logos/${rawData.logo}`}
                                                                                            />
                                                                                            {rawData.channel_name}
                                                                                        </div>
                                                                                    )}
                                                                                </td>
                                                                                <td>{rawData.finish}</td>
                                                                                <td>{rawData.device_id}</td>
                                                                                <td>{rawData.server_time}</td>
                                                                                <td>{rawData.start}</td>
                                                                                <td>{rawData.people}</td>
                                                                                <td>{rawData.offline}</td>
                                                                                <td>{rawData.id}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>



                                    ) : (
                                        <div className="card">
                                            <div className="card-header">
                                                <h1 class="card-title" style={{ color: "orange", fontWeight: "bold" }}>New Device Raw Data</h1>
                                                <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif?20170503175831" className="rounded mx-auto d-block" alt="Loading" />
                                            </div>
                                        </div>

                                    )

                                ) : (
                                    <div class="card">
                                        <div class="card-header">
                                            <div className="col-md-7 d-flex align-items-center text-nowrap">
                                                {/* Device Box Info Title */}
                                                <h1 className="card-title mx-2" style={{ color: "orange", fontWeight: "bold" }}>New Device Raw Data</h1>

                                                {/* Refresh Button */}
                                                <button
                                                    className="btn btn-warning btn-sm d-flex align-items-center mb-2"
                                                    onClick={handleRefreshNewDeviceRawData}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="18"
                                                        height="18"
                                                        fill="currentColor"
                                                        className="bi bi-arrow-clockwise"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                                    </svg>
                                                </button>
                                            </div>
                                            <h4><span class="danger">{errorMsgNewDeviceRawData}</span></h4>
                                        </div>
                                    </div>
                                )}


                            </>


                        ) : (
                            <div className="card">
                                <div className="card-header">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif?20170503175831" className="rounded mx-auto d-block" alt="Loading" />
                                </div>
                            </div>
                        )}




                        <br />
                        <br />




                    </div>
                </div>


            </div>
        </div>




    )
}
export default DeviceDeployment;

